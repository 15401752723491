import { Amplify } from '@aws-amplify/core';
import { Authenticator } from '@aws-amplify/ui-react';
import { Fallback } from '@cfra-nextgen-frontend/shared';
import { ErrorBoundary } from '@cfra-nextgen-frontend/shared/src/components/Fallbacks/ErrorBoundary';
import { ENVIRONMENT_NAME } from '@cfra-nextgen-frontend/shared/src/config';
import { queryClient } from '@cfra-nextgen-frontend/shared/src/lib/react-query-client';
import { Environments } from '@cfra-nextgen-frontend/shared/src/utils';
import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from 'components/themes/theme';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { awsConfigAuth, endpointsConfig } from '../config/auth';
import { AppRoutes } from '../routes/AppRoutes';
Amplify.configure({ Auth: awsConfigAuth, API: { endpoints: endpointsConfig } });

export function AppProvider() {
    return (
        // Ensures We Error Gracefully
        <ErrorBoundary FallbackComponent={Fallback.ErrorFallback}>
            {/* React Query Client Provider */}
            <QueryClientProvider client={queryClient}>
                {/* Add React Query Dev tools in development */}
                {ENVIRONMENT_NAME === Environments.Local && <ReactQueryDevtools />}
                {/* MUI Theme Provider */}
                <ThemeProvider theme={appTheme}>
                    <Authenticator.Provider>
                        <AppRoutes />
                    </Authenticator.Provider>
                </ThemeProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}
