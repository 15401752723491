import { topNavigationHeight } from '@cfra-nextgen-frontend/shared/src/utils/lookAndFeel';
import { ChatHistoryDrawer } from 'features/chatHistory';
import { TopNavigation } from 'features/topNavigation';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';


export default function AuthenticatedRoutesWrapper() {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const isDrawerVisible = isDrawerOpen || isLargeScreen;

    return (
        <>
            <TopNavigation topNavigationContentHeight={topNavigationHeight} toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)} />
            <ChatHistoryDrawer isDrawerOpen={isDrawerVisible} />
            <Box sx={{ marginLeft: isDrawerVisible ? '250px' : '0px', maxWidth: isDrawerVisible ? 'calc(100vw - 250px)' : '100vw' }}>
                <Outlet />
            </Box>
        </>
    );
}
