import { useAuthenticator } from '@aws-amplify/ui-react';
import { Fallback } from '@cfra-nextgen-frontend/shared';
import { ProjectSpecificResourcesContextProvider } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { Login } from 'features/auth/components/Login';
import { HomeRoutes } from 'features/home/routes';
import { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AppRoutePaths } from 'utils/enums';
import AuthenticatedRoutesWrapper from './AuthenticatedRoutesWrapper';

const routes = [
    { path: AppRoutePaths.Home, element: <HomeRoutes /> },
];

export function getRoutes(userName?: string) {
    return (
        <Route
            element={
                <ErrorBoundary FallbackComponent={Fallback.ErrorFallback}>
                    <ProjectSpecificResourcesContextProvider
                        projectSpecificResources={{
                            userId: userName,
                        }}>
                        <AuthenticatedRoutesWrapper />
                    </ProjectSpecificResourcesContextProvider>
                </ErrorBoundary>
            }
            children={routes.map((route) => {
                const { path, element } = route;
                return (
                    <Route
                        key={path}
                        path={path}
                        element={element}
                    />
                );
            })}
        />
    );
}

export const AppRoutes = () => {
    const { route, user } = useAuthenticator((context) => [context.route, context.user]);

    const routesJsx = useMemo(() => getRoutes(user?.username), [user?.username]);

    const browserRouter = useMemo(() => {
        return createBrowserRouter(createRoutesFromElements(routesJsx));
    }, [routesJsx]);

    if (route !== 'authenticated' || user?.username === undefined) {
        return <Login />;
    }

    return <RouterProvider router={browserRouter} />;
};
