import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { useQuery, useQueryClient } from 'react-query';
import { BASE_API_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { fetchWithAuth } from '../../../utils/api';

export function UsageSummary({ labelText }: { labelText: string }) {
    const queryClient = useQueryClient();
    const { data: usage = 0, isLoading: isLoadingUsage } = useQuery<number>(
        ['usage'],
        async () => {
            const api = `${BASE_API_URL}/usage`;
            const response = await fetchWithAuth(api);
            return response.json();
        }
    );

    return <Chip 
        label={isLoadingUsage ? 'Loading...' : `${labelText}$${(+usage).toFixed(2)}`} 
        avatar={<Avatar>$</Avatar>} 
        variant="outlined"
        color={usage < 5 ? "primary" : (usage < 10 ? "warning" : "error")}
        onClick={() => {
            queryClient.invalidateQueries(['usage']);
        }}
        title="Shows current monthly usage. Click to refresh data."
    />;    
}
