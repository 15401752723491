import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Markdown from 'react-markdown';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { BASE_API_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { DefaultCFRASnack } from "@cfra-nextgen-frontend/shared";
import { SnackMessageForm } from "@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { fetchWithAuth } from '../../../utils/api';


type ChatMessage = {
    role: 'user' | 'assistant';
    text: string;
    created: string;
    model_name?: string;
    cost?: number;
};

export function ChatWindow(props: { threadId: string }) {
    const [newMessage, setNewMessage] = useState("");
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);
    const queryClient = useQueryClient();
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const lastMessageRef = useRef<HTMLDivElement>(null);

    const { data: messages = [], isLoading: isLoadingMessages } = useQuery<ChatMessage[]>(
        ['messages', props.threadId],
        async () => {
            if (props.threadId === '0' || parseInt(props.threadId) <= 0) {
                ShowSnack(SnackMessageForm({
                    message: 'Link is not valid. Navigating to home page.',
                }));
                navigate('/');
                return [];
            }
            
            const api = `${BASE_API_URL}/messages/${props.threadId}`;
            const response = await fetchWithAuth(api);
            return response.json();
        },
        {
            enabled: props.threadId !== '0' && parseInt(props.threadId) > 0,
        }
    );

    useEffect(() => {
        if (chatContainerRef.current) {
            lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const { mutate: sendMessage, isLoading: isSendingMessage } = useMutation(
        async () => {
            const api = `${BASE_API_URL}/chat`;
            const newMessageObject = {
                role: "user" as const,
                text: newMessage,
                created: new Date().toISOString(),
            };
            
            const response = await fetchWithAuth(api, {
                method: 'POST',
                body: JSON.stringify({
                    messages: [...messages, newMessageObject],
                    thread_id: props.threadId
                })
            });

            if (!response.ok) throw new Error("Failed to send message");
            
            // If the date has changed since the previous message, update threads
            const result = await response.json();
            if (new Date(messages[messages.length - 1].created).toLocaleDateString() !== new Date(result.created).toLocaleDateString()) {
                queryClient.invalidateQueries('threads');
            }

            return { newMessageObject, result };
        },
        {
            onSuccess: ({ newMessageObject, result }) => {
                // Update the messages
                queryClient.setQueryData(['messages', props.threadId], 
                    [...messages, newMessageObject, result]
                );

                // Update the usage
                const currentUsage = queryClient.getQueryData<number>(['usage']) ?? 0;
                queryClient.setQueryData(['usage'], (+currentUsage) + (result.cost ?? 0));

                setNewMessage("");
            },
            onError: () => {
                ShowSnack(SnackMessageForm({
                    message: 'Failed to send message',
                }));
            }
        }
    );

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (newMessage.trim()) {
            sendMessage();
        }
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" sx={{ minHeight: 'calc(100vh - 90px)', backgroundColor: '#f8f8f8', margin: '0 10px' }}>
            {
                isLoadingMessages ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                    </Box>
                ) : null
            }
            {
                !isLoadingMessages && messages.length === 0 ? <Box display="flex" justifyContent="center" alignItems="center" height="100%" flexGrow={1}>
                    <Typography variant="body1" color="text.primary">No messages available.</Typography>
                </Box> : null
            }
            {   
                !isLoadingMessages && messages.length > 0 && (
                <>
                    <Box className="chat-container" display="flex" flexDirection="column" flexGrow={1} flexShrink={0} overflow="auto" gap={1} 
                        ref={chatContainerRef}
                        sx={{ maxHeight: 'calc(100vh - 190px)', overflowY: 'auto', padding: '10px', '& p': { margin: 0 } }}>
                        
                            {messages.map((t, i) => 
                                <Paper key={i} elevation={3} 
                                    sx={{ maxWidth: '70%', padding: '10px', alignSelf: t.role === 'assistant' ? 'flex-start' : 'flex-end' }}>
                                    {i === messages.length - 1 && <span ref={lastMessageRef}></span>}
                                    {
                                        t.role === 'assistant' ?
                                            <Typography variant="body2" color="text.secondary" fontWeight="bold" display="flex" alignItems="center" gap={1}>
                                                <SmartToyTwoToneIcon fontSize="small" />    
                                                Assistant
                                            </Typography> :
                                            <Typography variant="body2" color="text.secondary" fontWeight="bold" display="flex" alignItems="center" gap={1}>
                                                <AccountCircleTwoToneIcon fontSize="small" />
                                                You
                                            </Typography>
                                    }
                                    <Markdown>{t.text}</Markdown>
                                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '10px' }}>
                                        <Button
                                            variant="text"
                                            size="small"
                                            startIcon={<ContentCopyIcon fontSize="inherit" />}
                                            onClick={async () => {
                                                await navigator.clipboard.writeText(t.text);
                                            }}
                                            sx={{ 
                                                fontSize: '11px',
                                                color: 'text.secondary',
                                                padding: '0px 4px',
                                                minWidth: 0,
                                                textTransform: 'none',
                                            }}
                                        >
                                            Copy
                                        </Button>
                                        <Typography variant="caption" color="text.secondary" fontSize="11px">
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {t.role === 'assistant' && t.model_name ? `Model: ${t.model_name}` : null}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {t.role === 'assistant' && t.cost ? `Cost: $${(+t.cost).toFixed(3)}` : null}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {t.role === 'assistant' && t.text ? `Length: ${t.text.length}` : null}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {new Date().toLocaleDateString() === new Date(t.created).toLocaleDateString() ? new Date(t.created).toLocaleTimeString() : new Date(t.created).toLocaleString()}
                                        </Typography>
                                    </Box>
                                </Paper> 
                            )}
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="stretch" flexGrow={0} justifyContent="space-between" sx={{ height: '100px' }}
                        component="form" onSubmit={handleSubmit} gap={1}>
                        <TextField label="Enter your message here (Ctrl+Enter to send)" multiline 
                            maxRows={3} variant="outlined" margin="dense" fullWidth
                            value={newMessage} onChange={e => setNewMessage(e.target.value)} 
                            onKeyDown={e => {
                                if (e.ctrlKey && e.key === 'Enter') {
                                    handleSubmit(e);
                                }
                            }}
                            sx={{ 
                                margin: 0, 
                                height: '100%',
                                '& .MuiInputBase-root': {
                                    height: '100%'
                                }
                            }}
                            disabled={isSendingMessage}
                        />
                        <Button 
                            variant="contained" 
                            endIcon={isSendingMessage ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
                            onClick={handleSubmit} 
                            disabled={newMessage.length === 0 || isSendingMessage}
                        > 
                            {isSendingMessage ? 'Sending...' : 'Send'}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}
