import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { styled } from '@mui/material';

const LogoText = styled('span')({
    fontFamily: fontFamilies.GraphikBold,
    color: '#002b5a',
    lineHeight: 1,
});

const LogoEmphasizedText = styled('span')({
    color: '#117bb6',
});

export default function TextLogo({ fontSize }: { fontSize: number }) {
    return (
        <LogoText sx={{ display: 'flex', fontSize: `${fontSize}px` }}>
            CFRA <LogoEmphasizedText> Copilot</LogoEmphasizedText>
        </LogoText>
    );
}
