import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { homePageTheme } from 'components/themes/theme';
import { useParams } from 'react-router-dom';
import { ChatWindow } from 'features/chat';
import { NewChat } from 'features/newChat';
import { SnackbarProvider } from 'notistack';

export function Home() {
    const { threadId } = useParams();

    return (
        <ThemeProvider theme={homePageTheme}>
            <SnackbarProvider>
                <Container className="chat-window-container">
                    <Box>
                        {threadId === "0" || !threadId ? <NewChat /> : <ChatWindow threadId={threadId ?? "-1"} />}
                    </Box>
                </Container>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
