import { Authenticator, Button, Heading, Text, View, useAuthenticator, useTheme } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextLogo from 'components/TextLogo/TextLogo';

export function Login() {
    const components = {
        Header() {
            return (
                <Box sx={{ display: 'flex', margin: 5, justifyContent: 'center' }}>
                    <TextLogo fontSize={50} />
                </Box>
            );
        },
        Footer() {
            const { tokens } = useTheme();

            return (
                <View textAlign='center' padding={tokens.space.large}>
                    <Text color={tokens.colors.neutral[80]}>&copy; Footer</Text>
                </View>
            );
        },
        SignIn: {
            Header() {
                const { tokens } = useTheme();

                return (
                    <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
                        Sign in to Your account
                    </Heading>
                );
            },
            Footer() {
                const { toResetPassword } = useAuthenticator();

                return (
                    <View textAlign='center'>
                        <Button fontWeight='normal' onClick={toResetPassword} size='small' variation='link'>
                            Reset Password
                        </Button>
                    </View>
                );
            },
        },
        SignUp: {
            Header() {
              const { tokens } = useTheme();
        
              return (
                <Heading
                  padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                  level={3}
                >
                  Create a new account
                </Heading>
              );
            },
            Footer() {
              const { toSignIn } = useAuthenticator();
        
              return (
                <View textAlign="center">
                  <Button
                    fontWeight="normal"
                    onClick={toSignIn}
                    size="small"
                    variation="link"
                  >
                    Back to Sign In
                  </Button>
                </View>
              );
            },
            FormFields() {
                const { validationErrors } = useAuthenticator();
    
                return (
                    <>
                        <Authenticator.SignUp.FormFields />
                        {validationErrors.username === 'Emails must match' && (
                            <Text variation="error">
                                Emails must match
                            </Text>
                        )}
                    </>
                );
            }
        },
        ConfirmSignUp: {
            Header() {
                const { tokens } = useTheme();
                return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
                );
            },
            Footer() {
                return <Text></Text>;
            },
        },
    };

    const formFields = {
        signIn: {
            username: {
                placeholder: 'Enter Your Email',
                isRequired: true,
                label: 'Email',
            },
        },
        signUp: {
            username: {
                order: 1,
                placeholder: 'Enter Your Email',
                label: 'Email',
                isRequired: true,
            },
            email: {
                order: 2,
                placeholder: 'Please confirm your Email',
                label: 'Confirm Email',
                isRequired: true,
            },
            password: {
                order: 3
            },
            confirm_password: {
                order: 4
            },
        },
    };

    const services = {
        async validateCustomSignUp(formData: any) {
            if (formData.username !== formData.email) {
                return {
                    username: 'Emails must match'
                } as any;
            }
            return {};
        },
    };

    return (
        <Container>
            <Authenticator formFields={formFields} components={components} hideSignUp={false} services={services} />
        </Container>
    );
}
