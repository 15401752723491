import { Auth } from '@aws-amplify/auth';
import {
    AUTH_REGION,
    AUTH_USER_POOL_ID,
    AUTH_USER_POOL_WEB_CLIENT_ID,
    BASE_API_URL,
} from '@cfra-nextgen-frontend/shared/src/config';
import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';

console.log(AUTH_USER_POOL_ID)
console.log(AUTH_USER_POOL_WEB_CLIENT_ID)
export const awsConfigAuth = {
    region: AUTH_REGION,
    userPoolId: AUTH_USER_POOL_ID,
    userPoolWebClientId: AUTH_USER_POOL_WEB_CLIENT_ID,
};

export const endpointsConfig = [
    {
        name: ApiNames.AI,
        endpoint: BASE_API_URL,
        custom_header: async () => {
            return {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Content-Type': 'application/json',
            };
        },
    },
];
